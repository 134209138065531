(function($){$.fn.extend({device_confirm:function(){var userAgentInfo = navigator.userAgent;var Agents = ["Android", "iPhone","SymbianOS", "Windows Phone","iPad", "iPod"];var flag = true;for (var v = 0; v < Agents.length; v++) {if (userAgentInfo.indexOf(Agents[v]) > 0) {flag = false;break;}}return flag;}})})(jQuery);(function($){$.fn.extend({system_confirm:function(){var userAgentInfo = navigator.userAgent;if(userAgentInfo.indexOf("Win")>-1){return "win"}else if(userAgentInfo.indexOf("Mac")>-1){return "mac"}}})})(jQuery);if(window.innerWidth>956){$(".tab_index").mouseenter(function(){$("#submenu_container").addClass("active"); $(".submenu").eq($(this).index(".tab_index")).addClass("active").siblings(".submenu").removeClass("active");});$("#submenu_container").mouseleave(function(){$("#submenu_container").removeClass("active");$(".submenu").removeClass("active");});};function system_confirm(){var userAgentInfo = navigator.userAgent;if(userAgentInfo.indexOf("Win")>-1){return "win"}else if(userAgentInfo.indexOf("Mac")>-1){return "mac"}};function device_confirm(){var userAgentInfo = navigator.userAgent;var Agents = ["Android", "iPhone","SymbianOS", "Windows Phone","iPad", "iPod"];var flag = true;for (var v = 0; v < Agents.length; v++) {if (userAgentInfo.indexOf(Agents[v]) > 0) {flag = false;break;}}return flag;};
(function () {
    var isMac,device_com;
    if(system_confirm()=="mac"){isMac=true}
    else if(system_confirm()=="win"){isMac=false}
    if(device_confirm()==true){device_com=true}
    else if(device_confirm()==false){device_com=false}
    if (isMac && device_com == true) {
        $('.mac_btn_box').css({"display":"flex"});
        $('.mac_btn').css({"display":"block"});
        $('.win_btn_box').css({"display":"none"});
        $('.win_btn').css({"display":"none"});
        $('.mobile_btn').css({"display":"none"});
    } else if (!isMac && device_com == true) {
        $('.win_btn_box').css({"display":"flex"});
        $('.win_btn').css({"display":"block"});
        $('.mac_btn_box').css({"display":"none"});
        $('.mac_btn').css({"display":"none"});
        $('.mobile_btn').css({"display":"none"});
      
    } else if (device_com == false) {
        $('.pc_btn').css({"display":"none"});
        $('.mobile_btn').show();
    };
})();
function resizeHeader(){
    if($(window).width()>1200){
      $('.header_box>ul').addClass('header_nav');
      $('.header_box>ul').show();
      $('.header_box>ul>li').unbind('click');
      $('.header_box>ul>li .menu dl dt').unbind('click');
      $('.header_box .toggle').removeClass('active');
      $('.header_box>ul').removeClass('active');
      $('.header_box>ul>li').removeClass('active');
      $('.header_box>ul>li .menu dl').removeClass('active');
    }else{
      $('.header_box>ul>li').unbind();
      $('.header_box>ul').removeClass('header_nav');
      $('.header_box>ul>li .menu dl dt').unbind('click');
      $('.header_box>ul>li *').click(function(e){
        e.stopPropagation();
      })
      $('.header_box>ul>li .menu dl dt').click(function(){
        $(this).parent().toggleClass('active').siblings().removeClass('active');
        $(this).parent().parent().siblings().find('dl').removeClass('active');
        $(this).parents('.system').siblings().find('dl').removeClass('active');
        $(this).parents('li').siblings('li').find('dl').removeClass('active');
      })
      $('.header_box>ul>li').click(function(){
        $(this).toggleClass('active').siblings().removeClass('active');
      })
    }
};
resizeHeader();
$(window).resize(function(){
    resizeHeader();
});
$('.toggle').click(function(){
  $(this).toggleClass('active');
  $('.header_box>ul').slideToggle('active');
});


function resizeAcount(){
    //宽度小于550
    if($(window).width()>1200){
        $('.header .login_btn').unbind()
        $('.header').unbind()
        $('.header .login_box').unbind()
        $('.header .login_box ul').removeAttr('style')
        $('.header .login_btn').mouseover(function(){
            $('.header .login_btn .login_box ul').show();
        });
        $('.header').mouseout(function(){
            $('.header .login_btn .login_box ul').hide();
        });
     }else{
         $('.header .login_box ul').width($(window).width());
         $('.header .login_box').unbind()
         $('.header .login_btn').unbind()
         $('.header').unbind()
         $('.header .login_box').click(function(e){
             e.stopPropagation();
             if($('.header_box>ul').css("display")!='none'){
                 $('.header_box>ul').hide();
                 $('.header_box .toggle').removeClass('active');
             }
             if($('.header .login_box ul').css("display")=='none'){
                 $('.header .login_box ul').show();
                 $('.nav_bg').show();
             }else{
                 $('.header .login_box ul').hide();
                 $('.nav_bg').hide();
             }
         });
     }
}
resizeAcount()
$(window).resize(function(){
    resizeAcount();
});
$('.header_box .toggle').click(function(){
    if($('.header .login_box ul').css("display")=='block'){
        $('.header .login_box ul').hide();
    }
})

console.log("v5.0");